//
// TinyMCE
//




// Base
.tox-target{
    display: none;
}

.tox-tinymce{
    border-radius: $inf-border-radius !important;
    height: 500px !important;
}   
