//
// NoUiSlider
//




// Base
@mixin inf-nouislider-base() {
    .noUi-target {
        @include inf-not-rounded {
           border-radius: 0 !important;
        }
        
        &.noUi-horizontal {
            height: 15px;

            .noUi-handle {
                width: 24px;
                height: 24px;
                left: -17px;
                top: -6px;
                border-radius: 50%;
                outline: none;

                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }                
            }
        }

        &.noUi-vertical {
            height: 150px;
            width: 15px;

            .noUi-handle {
                width: 24px;
                height: 24px;
                left: -5.5px;
                top: -6px;
                border-radius: 50%;
                outline: none;

                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }                
            }
        }


        // State colors
        &.nouislider {
            @each $name, $color in $inf-state-colors {
                &.nouislider-connect-#{$name} {
                    .noUi-connect {
                        background: inf-get($color, base);
                    }
                }

                &.nouislider-handle-#{$name} {
                    .noUi-handle {
                        border: 0;
                        background: inf-get($color, base);
                        box-shadow: 0 3px 6px -3px inf-get($color, base);
                    }
                }
            }   
        }
    }
}

// Skin
@mixin inf-nouislider-skin() {
    .noUi-target {
        border: 1px solid $input-border-color;  
        background: inf-base-color(grey, 1);
        box-shadow:none; 

        .noUi-connect {
            background: inf-state-color(brand, base);
        }  

        .noUi-handle {
            border: 1px solid inf-base-color(grey, 1);
            box-shadow: 0 3px 6px -3px rgba(#000, 0.7);
        }
    }    
}


// Base
@include inf-nouislider-base();

// Skin
@include inf-nouislider-skin();