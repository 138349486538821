
//
// Option
//




// Base
@mixin inf-option-base() {
    .inf-option  {
        display: flex;       
        padding: 1.4em;        
        border: 1px solid inf-base-color(grey, 2);

        @include inf-rounded {
            border-radius: $inf-border-radius;
        }
        
        .inf-option__control {
        	width: 2.7rem;
            padding-top: 0.1rem;      	

        	.inf-radio {
        		display: block;
        	}
        }

        .inf-option__label {
            width: 100%;

        	.inf-option__head {
        		display: flex;
                justify-content: space-between;                

        		.inf-option__title {
        			font-size: 1.1rem;    
                    font-weight: 400;     	
                    color: inf-base-color(label, 3);
        		}

        		.inf-option__focus {
        			font-size: 1.1rem;
    				font-weight: 500; 		
                    color: inf-base-color(label, 4);
        		}
        	}

        	.inf-option__body {
        		display: block;
        		padding-top: 0.7rem; 
        		font-size: 0.9rem;     	
                color: inf-base-color(label, 2);
        	}
        }

        &.inf-option--plain {
            border: 0;
            padding: 0; 
            margin-top: -0.2rem;
            margin-bottom: 2rem; 
            align-items: center;      

            .inf-option__control {
                vertical-align: middle;                                               
                width: 2.7rem;

                .inf-radio {
                    margin-bottom: 1.6rem;                     
                }
            }

            .inf-option__label {
                .inf-option__head {
                    .inf-option__title {
                                                           
                    }                    
                }

                .inf-option__body {
                    padding-top: 0.2rem;                              
                }
            }
        }

        // Validation 
        .is-valid & {
            border-color: inf-state-color(success, base);
        }

        .is-invalid & {
            border-color: inf-state-color(danger, base);
        }
    }     


}

// Validation State
@mixin inf-option-validation-state($state, $color) {
    .has-#{$state} {
        .inf-option {
            .inf-option__label {
                .inf-option__head {
                    .inf-option__title {
                        color: $color;
                    }
                }
            }
        }
    }
}

// Base
@include inf-option-base();

// Validation States
@include inf-option-validation-state(success, inf-get($inf-state-colors, success, base));
@include inf-option-validation-state(error, inf-get($inf-state-colors, warning, base));