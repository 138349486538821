//
// Footer Config
//



$inf-footer-config: (
	self: (
		fixed: (		 
			zindex: 97,
			height: 60px
		)
	)
)