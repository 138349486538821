//
// Notification
//



$inf-notification-item-padding-x: 1.5rem;
$inf-notification-item-padding-y: 1.1rem;

.inf-notification {
	// Item
	.inf-notification__item {
		display: flex;
		align-items: center;
		padding: $inf-notification-item-padding-y $inf-notification-item-padding-x;
		border-bottom: 1px solid inf-base-color(grey, 1);
		transition: background-color 0.3s ease;

		&:last-child {
			border-bottom: 1px solid transparent;
		}

		.inf-notification__item-icon {
			display:flex;
			flex: 0 0 2.5rem;
			align-items: center;

			i {
				font-size: 1.4rem;
				color: inf-brand-color();
			}

			svg {
				height: 24px;
				width: 24px;
				margin-left: -4px;
			}
		}

		.inf-notification__item-details {
			display:flex;
			flex-direction: column;
			flex-grow: 1;

			.inf-notification__item-title {
				transition: color 0.3s ease;
				font-size: 1rem;
		   		font-weight: 400;
		   		color: inf-base-color(label, 3);
			}

			.inf-notification__item-time {
				font-size: 1rem;
		   		font-weight: 300;
		   		color: inf-base-color(label, 2);
			}
		}

		&:hover {
			transition: background-color 0.3s ease;
			text-decoration: none;
			background-color: inf-base-color(grey, 1);

			.inf-notification__item-details {
				.inf-notification__item-title {
					transition: color 0.3s ease;
					color: inf-brand-color();
				}
			}	
		}

		&.inf-notification__item--read {
			& {
				opacity: 0.5;
			}
		}
	}

	// Custom
	.inf-notification__custom {
		display: flex;
		align-items: center;
		border-top: 1px solid inf-base-color(grey, 1);
		padding: ($inf-notification-item-padding-y + 0.5) $inf-notification-item-padding-x;
	}

	&.inf-notification--fit {
		.inf-notification__item {
			padding: $inf-notification-item-padding-y / 2 0;

			&:hover {
				background-color: transparent;

				.inf-notification__item-title {
					color: inf-brand-color();
				}
			}
		}
	}
}

// Util classes
.inf-notification-item-padding-x {
	padding-left: $inf-notification-item-padding-x !important;
	padding-right: $inf-notification-item-padding-x !important;
}

.inf-notification-item-margin-x {
	margin-left: $inf-notification-item-padding-x !important;
	margin-right: $inf-notification-item-padding-x !important;
}
