// load base demo styles. for demo purpose, the style will be loaded here
// for production, include the demo base style in /src/index.html file to load the css earlier
.inf-grid--animateContent {
  transform: translate3d(0, -5%, 0);
  opacity: 0;
  position: static;
  &-finished {
    transform: translate3d(0, 0%, 0);
    transition: 0.2s ease-in-out;
    opacity: 1;
  }
}
   

:root {
  --greyLight: #23adade1;
  --greyLight-2: #cbe0dd;
  --greyDark: #2d4848;
}

ul {
  list-style-type: none;
}

.items-list {
  max-width: 90vw;
  margin: 2rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem;
  justify-content: center;
  align-content: center;

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.item {
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--greyDark);
  cursor: pointer;

  span {
    background: #ffffff;
    box-shadow: 0 0.8rem 2rem rgba(#5a6181, 0.05);
    border-radius: 0.6rem;
    padding: 2rem;
    transition: all 0.3s ease;
  }

  &:hover {
    span {
      transform: scale(1.2);
      color: var(--primary);
    }
  }

  p {
    margin-top: 1rem;
    color: var(--greyLight);
  }
}
.break, .previous, .next {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  font-size: 1.4rem;
  cursor: pointer;
}
.page {
  display: flex;
  justify-content: right;
  align-items: center;

  &__numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    cursor: pointer;
  }

  &__numbers {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 0.4rem;

    &:hover {
      color: var(--primary);
    }

    &.active {
      color: #ffffff;
      background: var(--primary);
      font-weight: 600;
      border: 1px solid var(--primary);
    }
  }
}
