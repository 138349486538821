//
// Link
//




.inf-link {
    text-decoration: none;
  	position: relative;
  	display: inline-block;

    &:after {
    	display: block;
    	content: '';
		position: absolute;
		bottom: 0;
        top: 1rem;  
		left: 0;
		width: 0%;
		transition: width 0.3s ease;
    }

    &:hover {
    	text-decoration: none !important;

    	&:after {				
    		width: 100%;
    	}
    }

    @include inf-link-color(inf-get($inf-font-color, link, default), inf-get($inf-font-color, link, hover));

    @each $name, $color in $inf-state-colors {
        &.inf-link--#{$name} {
            @include inf-link-color(inf-get($color, base), darken(inf-get($color, base), 10%));
        }
    }
}