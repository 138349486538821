.inf-main{
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.inf-logo-container .logo {
  width: 15%;
}

.inf-logo-container {
  display: flex;
  justify-content: center;
}
.inf-main-section h1 {
  text-align: center;
  color: black;
  font-size: 2rem;
  padding-top: 20px;
}

.inf-main-search-section {
  display: flex;
  justify-content: center;
  margin: 20px 0 20px 0px;
}
.inf-main-section{
  padding-bottom: 100px;
}

.inf-header-search {
  color: #fff;
}

.inf-header-search, .inf-header-search .flaticon2-search-1 {
  color: #fff;
}

.inf-header-search .inf-spinner.inf-spinner--brand:before {
  border: 2px solid #fff;
  border-right: 2px solid transparent;
}

.inf-mainsearch__wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.inf-mainsearch__wrapper .btn-icon {
  background: #e1e3ec;
  color: #959cb6;
}

.inf-mainsearch__wrapper .btn-icon:focus,
.inf-mainsearch__wrapper .btn-icon:hover
 {
  background: #124068 ;
}
.inf-wait-text{
  width: 50%;
}
.inf-remove-padding-left{
  padding-left: 0px !important;
}
.inf-remove-padding-right{
  padding-right: 0px !important;
}

.inf-loading-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inf-config-action {
  display: flex;
  align-items: center;
  justify-content: end;
}
.inf-config-action .btn {
 height: 35px;
}

.inf-config-edit {
  float: right; 
  padding: 5px 10px 5px 10px; 
  margin: 5px;
}

.inf-widget12_header{
  border-bottom: 1px solid #ebedf2;
}

.ant-form-item { 
  margin-bottom: 0px;
}

.inf-serach-wait {
  display: flex;
  justify-content: center;
}
.inf-serach-wait-text {
  color: #646c9a;
  padding-right: 50px;
}

.inf-search-container .row {
  margin-right: -20px;
  margin-left: -20px;
}

.inf-subheader__btn_chat, .inf-subheader__btn_chat i {
  color: #ffb822 !important;
}

.inf-subheader__btn_serach, .inf-subheader__btn_serach i {
  color: #3bb5e9 !important;
}


.inf-subheader__btn_chat:hover, .inf-subheader__btn_chat:hover i {
  color: #0c0c0b !important;
  background-color: #ffb822 !important;
}

.inf-subheader__btn_serach:hover, .inf-subheader__btn_serach:hover i {
  color: #eaf0f3 !important;
  background-color: #3bb5e9 !important;
}

.inf-conf-form .row {
  margin-right: 0px;
  margin-left: 0px;
}

.inf-conf-form {
  padding: 10px;
}
.inf-noresult-text {
  font-size: 1rem;
  font-weight: 500;
  color: #74788d;
}

.inf-bucket__desc {
  font-size: 1rem;
  font-weight: normal;
  color: #74788d;
}

.inf-file-preview {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  overflow: auto;
  display: flex;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  min-height: 60vh;
  max-height: 100vh;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}

.icon-2x {
  font-size: 2rem !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.inf-badge--use-list{
  font-size: 2rem;
  width: 65px;
  height: 65px;
}

.ant-descriptions-item-label, .ant-table-cell {
  font-size: 1.1rem;
  font-weight: 500;
  color: #595d6e;
}

.ant-table-cell {
  font-weight: 500;
  color: #595d6e;
}

.ant-descriptions-item-label {
  font-weight: 500 !important;
  color: #595d6e !important;
}

.btn-xsm{
  padding: .1rem .3rem;
  font-size: 0.80rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.border-bot{
  padding-bottom: 1rem;
  border-bottom: 1px dashed #ebedf2;
}

.inf-spinner-small:before {
  width: 10px;
  height: 10px;
  margin-top: -5px;
}

.btn-label-info:active {
  background-color: #5578eb;
  color: #ffffff;
}

.btn-label-danger:active{
  background-color: #fd397a;
  color: #ffffff;
}

.error_text {
  font-size: 12px;
  color: #fd397a;
}

.inf-table-icon {
 cursor: pointer;
}

.inf-table-icon:hover, .inf-table-icon:active {
  color: #3bb5e9;
  transition: color 0.3s ease;
}

.inf-full-width {
  width: 100%;
}

.inf-noresult-icon {
  color: rgb(255, 184, 34);
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 3rem;
}

.inf-error-icon {
  color: #fd397a;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 3rem;
}

.inf-noresult {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
}

.inf-search__input {
  background-color: transparent;
  border: none;
}

.inf-search__input_group_text {
  background-color: transparent;
  border: none;
}
.copyright {
  padding-top: 20px;
  text-align: center;
  font-size: 80%;
  color: #999999;
}

.inf-error-v1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.inf-error-v1__number {
  font-weight: 700;
  color: #fd397a;
  text-align: center;
}

.radio-span,
.form-lable .ant-form-item-label > label {
  color: #74788d;
  font-weight: 500;
}

.ant-input, .ant-select-selector, .ant-modal-content, .ant-input-password{
  border-radius: 0px;
}

.ant-modal-content {
  border-radius: 0px !important;
}

.ant-modal-header {
  margin-bottom: 10px;
  border-bottom: solid 1px #3bb5e9;
}

.ant-btn-default {
  line-height: 1.5;
  background-color: rgba(40, 42, 60, 0.1);
  color: #282a3c;
  border-radius: 0.2rem;
}

.ant-btn-default:hover{
  background-color: #282a3c !important;
  color: #ffffff !important;
  border-color: #282a3c !important;
}

.ant-btn-primary {
  background-color: #3bb5e9;
  color: #ffffff;
  border-radius: 0.2rem;
  line-height: 1.5;
}
