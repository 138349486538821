//
// Timeline v3
//




.inf-timeline-v3 {
    .inf-timeline-v3__item {
        display: table;
        margin-bottom: 1rem;
        position: relative; // state bar

        &:before {
            position: absolute;
            display: block;
            width: 0.28rem;
            border-radius: 0.3rem;
            height: 70%;
            left: 5.1rem;
            top: 0.46rem;
            content: "";
        }
        
        .inf-timeline-v3__item-time {
            display: table-cell;
            vertical-align: top;
            padding-top: 0.6rem;
            font-weight: 500;
            font-size: 1.3rem;
            position: absolute;
            text-align: right;
            width: 3.57rem;
            color: inf-base-color(label, 2);
        } 
        
        // Description
        .inf-timeline-v3__item-desc {
            display: table-cell;
            width: 100%;
            vertical-align: top;
            padding-left: 7rem;
            
            .inf-timeline-v3__item-text {
                font-size: 1rem;
                font-weight: 400;
                color: inf-base-color(label, 2);
            }
            .inf-timeline-v3__item-user-name {
                .inf-timeline-v3__itek-link {
                    font-size: 0.9rem;
                    text-decoration: none;
                }
            }
        } 
        
        // States
        @each $name, $color in $inf-state-colors {
            &.inf-timeline-v3__item--#{$name} {
                &:before {
                    background: inf-get($color, base);
                }
            }
        }
    }
}

@include inf-mobile() {
    .inf-timeline-v3 {
        .inf-timeline-v3__item {
            margin-left: 0;
        }
    }
}