//
// Header
//




// Font settings
$inf-header-item-icon-color: rgba(inf-brand-color(), 0.7);
$inf-header-item-hover-bg: rgba(#f0f3ff, 0.7);

// Desktop mode
@include inf-desktop {

	.header-logo {
		width: 10%;
		height: 30%;
		margin: 20px 0px 0px 20px;
	}
	// Header
	.inf-header {
		display: flex;
		justify-content: space-between;
		height: inf-get($inf-header-config, base, desktop, default, height);
		transition: inf-get($inf-aside-config, base, minimize, transition);
		background-color: #fff;
		border-bottom: 1px solid #eff0f6;

		// Minimize 
		.inf-header--fixed:not(.inf-subheader--fixed) & {
			border-bottom: 0;
			box-shadow: 0px 0px 40px 0px rgba(82,63,105,0.1);
		}

		// Fixed
		.inf-header--fixed & {
			position: fixed;
			top: 0;
			right: 0;
			left: inf-get($inf-aside-config, base, default, width);
			z-index: inf-get($inf-header-config, base, desktop, fixed, zindex);
		}

		// Fixed and minimized header
		.inf-header--fixed.inf-header--minimize & {
			height: inf-get($inf-header-config, base, desktop, fixed, minimizeHeight);
			transition: height 0.3s ease;
		}

		// Minimize Aside
		.inf-header--fixed.inf-aside--minimize & {
			left: inf-get($inf-aside-config, base, minimize, width);
			transition: inf-get($inf-aside-config, base, minimize, transition);
		}
	}
}

// Mobile mode
@include inf-tablet-and-mobile {
	// Topbar
	.header-logo {
		display: none;
	}
	.inf-header__topbar {
		background-color: #ffffff;
		box-shadow: none;
	}

	// Mobile topbar view
	.inf-header__topbar--mobile-on {
		.header-logo {
			display: none;
		}
		.inf-header__topbar {
			box-shadow: 0px 0px 40px 0px rgba(82,63,105,0.1);
			border-top: 1px solid inf-base-color(grey, 2);
		}
	}
}

