//
// Loader
//




// Config
$inf-loader-config: (
	default: (
		size: 1.4rem,
		width: 2px
	),
	lg: (
		size: 2rem,
		width: 3px 
	),
	sm: (
		size: 1rem,
		width: 1px
	)
);

// Base
@mixin inf-loader-base() {
	// Spinner markup
	.inf-loader {
	    display: inline-block;
	    width: inf-get($inf-loader-config, default, size);
	    height: inf-get($inf-loader-config, default, size);
	    margin: 0 auto;
	    border-radius: 100%;  
	    background-color: inf-base-color(grey, 1);
	    opacity: 0;
	    animation: inf-loader-scaleout 0.9s infinite ease-in-out;

	    &.inf-loader--lg {
			width: inf-get($inf-loader-config, lg, size);
	    	height: inf-get($inf-loader-config, lg, size);
		}

		&.inf-loader--sm {
			width: inf-get($inf-loader-config, sm, size);
	    	height: inf-get($inf-loader-config, sm, size);
		}

		.btn & {
			position: relative;
			line-height: 0;
		}
	}

	// States
	@each $name, $color in $inf-state-colors {
        .inf-loader.inf-loader--#{$name} {
            background-color: inf-get($color, base);
        }
    }

	// Spinner animation
	@keyframes inf-loader-scaleout {
	    0% { 
	        opacity: 0.3;
	        transform: scale(0);
	    } 
	    100% {        
	        opacity: 1;
	        transform: scale(1.0);
	    }
	}
}

@mixin inf-loader-skin($color) {
	background-color: $color;
}

// Build
@include inf-loader-base();