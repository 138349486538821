//
// Form
//



.inf-form {
	.inf-form__group {
		&.inf-form__group--inline {
			display: table;
			padding: 0;

			> .inf-form__label {
				display: table-cell;
				vertical-align: middle;
				margin: 0;
				padding-right: 1rem;

				&.inf-form__label-no-wrap {
					white-space:nowrap;
				}

				> label {
					margin: 0;
				}
			}

			> .inf-form__control {
				display: table-cell;
				vertical-align: middle;
				margin: 0;
				width: 100%;
			}
		}
	}

	// Action bar
	.inf-form__actions {
		&.inf-form__actions--right {
			text-align: right;
		}

		&.inf-form__actions--center {
			text-align: center;
		}
	}

	&.inf-form--center{
		width: 60%;
		margin: 0 auto;
	}
}

// Validation feedback
.is-valid {
	.valid-feedback {
		display: inline-block;
	}
}

.is-invalid {
	.invalid-feedback {
		display: inline-block;
	}
}

@include inf-desktop {
	.inf-form {
		&.inf-form--label-right {
			.form-group {
				label:not(.inf-checkbox):not(.inf-radio):not(.inf-option) {
					text-align: right;
				}
			}
		}
	}
}