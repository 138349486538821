//
// Widget29
//




.inf-widget29 {
    margin-top: 1rem;

    .inf-widget29__content {
        margin-bottom: 1.25rem;         
        padding: 2rem;
        background-color: #fff;

        .inf-widget29__title {
            font-size: 1.1rem;
            font-weight: 500;
            color: inf-base-color(label, 3);
        }

        .inf-widget29__item {
            display: flex;

            .inf-widget29__info {
                flex: 1;
                text-align: left;

                .inf-widget29__subtitle {
                    display: block;
                    color: inf-base-color(label, 2);
                    font-weight: 400;
                    font-size: 1rem;
                    padding: 0.25rem 0;
                }

                .inf-widget29__stats {
                    display: block;
                    font-size: 1.2rem;
                    font-weight: 500;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .inf-widget29__actions {
        margin-top: 1.5rem;
        padding: 0rem;
    }
}

 
@include inf-mobile {
	.inf-widget29 {
	    .inf-widget29__content {
	    	padding: 2.2rem 1rem;	

	    	.inf-widget29__item {
	    		.inf-widget29__info {
	    			> span {
	    				padding-right: 1rem;

	    				&:last-child {
	    					padding-right: 0;
	    				}
	    			}
	    		} 
	    	}    		 
	    }    	 	 	 
	}
}