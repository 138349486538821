//
// Mixins
//



@mixin inf-spinner-size($size) {
	&:before {
		width: inf-get($inf-spinner-config, size, $size);
  		height: inf-get($inf-spinner-config, size, $size);
  		margin-top: -(inf-get($inf-spinner-config, size, $size)/2);
  	}

	&.inf-spinner--center {
		&:before {
			left: 50%;
  			margin-left: -(inf-get($inf-spinner-config, size, $size)/2);
		}
	}

	&.inf-spinner--left {
		&:before {
			left: 0;
			right: auto;
		}
	}

	&.inf-spinner--right {
		&:before {
			left: auto;
			right: 0;
		}
	}
}

@mixin inf-spinner-skin($color) {
	&:before {
    	border: 2px solid $color;
		border-right: 2px solid transparent;
    }
}