//
// Content
//



@include inf-desktop {
	// Content
	.inf-content {
		padding: inf-get($inf-page-padding, desktop) 0;

		// Content fit
		&.inf-content--fit-top {
			padding-top: 0;
		}

		&.inf-content--fit-bottom {
			padding-bottom: 0;
		}

		// Footer fixed
		.inf-footer--fixed & {
			padding-bottom: inf-get($inf-footer-config, self, fixed, height);
		} 
	}
}

@include inf-tablet-and-mobile {
	// Content
	.inf-content {
		padding: inf-get($inf-page-padding, mobile) 0;

		// Content fit
		&.inf-content--fit-top {
			padding-top: 0;
		}

		&.inf-content--fit-bottom {
			padding-bottom: 0;
		}

		// Subheader enabled
		.inf-subheader--enabled.inf-subheader--transparent & {
			padding-top: 0;
		}
	}
}