//
// Recaptcha
//




// Base
@mixin inf-recaptcha-base() {
    .recaptcha {
        padding: 15px;
        
        @include inf-rounded {
            border-radius: $inf-border-radius;
        }

        .recaptcha-img {
            margin-bottom: 10px;
        }

        .input-group {
            .btn {
                i {
                    padding-right: 0;
                }
            }
            
            .form-control {
                @include inf-rounded {
                    border-top-left-radius: $inf-border-radius !important;
                    border-bottom-left-radius: $inf-border-radius !important;
                }
            }
        }
    }
}

// Component Skin
@mixin inf-recaptcha-skin() {
    .recaptcha {
        border: 1px solid inf-base-color(grey, 2);

        .recaptcha_only_if_incorrect_sol {
            color: inf-state-color(danger, base);
        }
    }
}

// Generate Component ====//

// Component Base
@include inf-recaptcha-base();

// Component Skin - Light
@include inf-recaptcha-skin();