//
// Base
//



// Body
body {
	background: $inf-content-bg-color;
}

// Base
.inf-subheader {
	&.inf-subheader--clear {
		margin-bottom: 0 !important;
	}
}

.inf-aside--fixed {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: inf-get($inf-aside-config, base, fixed, zindex);
}

// Desktop mode
@include inf-desktop {
	// Page
	.inf-wrapper {
		transition: inf-get($inf-aside-config, base, minimize, transition);
		padding-top: inf-get($inf-header-config, base, desktop, default, height);
		padding-left: inf-get($inf-aside-config, base, default, width);
		// Fixed Header
		// .inf-header--fixed & {
		// 	padding-top: inf-get($inf-header-config, base, desktop, default, height);
		// }

		// Fixed Content Head
		.inf-header--fixed.inf-subheader--fixed.inf-subheader--enabled & {
			padding-top: inf-get($inf-header-config, base, desktop, default, height);
		}

		// Fixed Aside
		.inf-aside--fixed & {
			padding-left: inf-get($inf-aside-config, base, default, width);
		}

		// Minimize Aside
		.inf-aside--fixed.inf-aside--minimize & {
			padding-left: inf-get($inf-aside-config, base, minimize, width);
			transition: inf-get($inf-aside-config, base, minimize, transition);
		}
	}

	// Subheader
	.inf-subheader {		
		// Fixed subheader mode
		.inf-header--fixed.inf-subheader--fixed & {
			position: fixed;			
			height: inf-get($inf-subheader-config, fixed, height);
			top: inf-get($inf-header-config, base, desktop, default, height);
			left: 0;
			right: 0;
			transition: all 0.3s ease;
			z-index: inf-get($inf-header-config, base, desktop, fixed, zindex) - 3;
			background-color: #fff;
			box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.08);
			margin: 0;
		}

		// Fixed header, minimized header and fixed subheader
		.inf-header--fixed.inf-header--minimize.inf-subheader--fixed & {
			top: inf-get($inf-header-config, base, desktop, fixed, minimizeHeight);
			transition: top 0.3s ease;
		}

		.inf-aside--enabled.inf-subheader--fixed & {
			left: inf-get($inf-aside-config, base, default, width);
		}
		
		.inf-subheader--fixed.inf-aside--minimize-hover &,
		.inf-subheader--fixed.inf-aside--minimize & {
			left: inf-get($inf-aside-config, base, minimize, width);
			transition: inf-get($inf-aside-config, base, minimize, transition);
		}
	}

	// Container 
	.inf-container {
		padding: 0 inf-get($inf-page-padding, desktop);
	}

	// Content & fixed subheader mode
	// .inf-subheader--enabled:not(.inf-subheader--fixed) {
	// 	.inf-content {
	// 		padding-top: 0;
	// 	}
	// }
}

// Fixed container width change to fluid
@include inf-media-range( lg, $inf-page-container-width + $inf-aside-default-width + (2 * inf-get($inf-page-padding, desktop)) ) {
	.inf-container:not(.inf-container--fluid) {
		width: 100%;
	}
}

// Tablet & mobile mode
@include inf-tablet-and-mobile {
	// Page
	.inf-wrapper {
		// Fixed Header
		.inf-header-mobile--fixed & {
			padding-top: inf-get($inf-header-config, base, mobile, self, default, height);
		}
	}

	// Container 
	.inf-container {
		padding: 0 inf-get($inf-page-padding, mobile);
	}

	// Content & fixed subheader mode
	.inf-subheader--enabled {
		.inf-content {
			padding-top: 0;
		}
	}

	.inf-subheader--enabled.inf-subheader--solid {
		.inf-subheader {		
			margin-bottom: inf-get($inf-page-padding, mobile);
		}
	}
}