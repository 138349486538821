//
// Grid Nav
//




// Component Base
@mixin inf-grid-nav-base() {
    .inf-grid-nav {
        padding:0;
        margin: 0; 
        display: table;
        table-layout: fixed;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;

        // nav row
        > .inf-grid-nav__row {
            display: table-row;       

            // nav item 
            > .inf-grid-nav__item {
                display: table-cell; 
                vertical-align: middle;   
                text-align: center;
                padding: 3.3rem 0.75rem;
                @include inf-transition();

                &:hover {
                    text-decoration: none;
                    @include inf-transition();
                }

                // nav icon
                .inf-grid-nav__icon {
                    text-align: center;
                    font-size: 2.5rem;
                }

                // nav text
                .inf-grid-nav__title {
                    display: block;
                    line-height: 1;
                    text-align: center;
                    margin: 10px 0 0 0;
                    font-size: 1.2rem;
                    font-weight: 500;
                }

                // nav text
                .inf-grid-nav__desc {
                    display: block;
                    line-height: 1;
                    text-align: center;
                    margin: 10px 0 0 0;
                    font-size: 1rem;
                    font-weight:400;
                }
            }
        }

        .dropdown-menu & {
            @include inf-rounded {
                border-bottom-left-radius: $inf-border-radius;
                border-bottom-right-radius: $inf-border-radius;
            }
        }
    }

    @include inf-tablet-and-mobile {
        .inf-grid-nav {
            // nav row
            > .inf-grid-nav__row {
                // nav item 
                > .inf-grid-nav__item {
                    padding: 1rem 0.5rem;
                }
            }  
        }
    }
}

// Component Skin
@mixin inf-grid-nav-skin() {
    .inf-grid-nav {
        .inf-grid-nav__row {
            .inf-grid-nav__item {
                border-right: 1px solid inf-base-color(grey, 2);
                border-bottom: 1px solid inf-base-color(grey, 2);

                &:last-child {
                    border-right: 0;
                }

                .inf-grid-nav__icon {
                    color: inf-state-color(brand);
                }

                .inf-grid-nav__title {
                    color: inf-brand-color();
                }                

                .inf-grid-nav__desc {
                    color: inf-base-color(label, 2);
                } 

                &:hover {
                    background: inf-base-color(grey, 1);

                    .inf-grid-nav__icon {
                        color: inf-base-color(label, 3);
                    }
                }   
            }

            &:last-child {
                .inf-grid-nav__item {
                    border-bottom: 0 !important;
                }
            }
        }

        // Dark skin
        &.inf-grid-nav--skin-dark {
            .inf-grid-nav__item {
                border-right: 1px solid rgba(#fff, 0.1);
                border-bottom: 1px solid rgba(#fff, 0.1);

                &:last-child {
                    border-right: 0;
                }

                .inf-grid-nav__icon {
                    color: inf-brand-color();
                }

                .inf-grid-nav__title {
                    color: #fff;
                }

                .inf-grid-nav__desc {
                    color: rgba(#fff, 0.5);
                }                

                &:hover {
                    background: transparent;

                    .inf-grid-nav__icon {
                        color: inf-brand-color();
                    }
                }   
            }
        }
    }
}

// Build
// Component Base
@include inf-grid-nav-base();

// Component Skin
@include inf-grid-nav-skin();