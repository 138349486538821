//
// Separator
//




.inf-separator {
    height: 0;
    margin: 20px 0;
    border-bottom: 1px solid inf-base-color(grey, 2);

    // Dashed style
    &.inf-separator--border-dashed,
    &.inf-separator--dashed {
        border-bottom: 1px dashed inf-base-color(grey, 2);
    }

    &.inf-separator--border-2x {
        border-bottom-width: 2px;
    }

    // No border
    &.inf-separator--space {
        border-bottom: 0;
    }

    // Sizing
    &.inf-separator--space-sm,
    &.inf-separator--sm {
        margin: 0.5rem 0;
    }

    &.inf-separator--space-md,
    &.inf-separator--md {
        margin: 2rem 0;
    }

    &.inf-separator--space-lg,
    &.inf-separator--lg {
        margin: 2.5rem 0;
    }

    &.inf-separator--space-xl,
    &.inf-separator--xl {
        margin: 3rem 0;
    }

    &.inf-separator--fit {
        margin-left: -(inf-get($inf-portlet, space, desktop));
        margin-right: -(inf-get($inf-portlet, space, desktop));
    }

    // States
    @each $name, $color in $inf-state-colors {
        &.inf-separator--#{$name} {
            border-bottom: 1px solid inf-get($color, base);

            &.inf-separator--dashed {
                border-bottom: 1px dashed inf-get($color, base);
            }
        }
    }
}
