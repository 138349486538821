//
// Burger Icon Mixins
//




@mixin inf-burger-icon-build($class, $config) {
	$space: inf-get($config, thickness) + inf-get($config, space);

	.#{$class} {
		display: inline-block;
	  	position: relative;
	  	overflow: hidden;
	  	margin: 0;
	  	padding: 0;
	  	font-size: 0;
	  	text-indent: -9999px;
	    appearance: none;
	    box-shadow: none;
	    border-radius: none; 
	    border: none;
	    cursor: pointer;
	    background: none;
	    outline: none !important;	    
	  	width: inf-get($config, width);
	  	height: inf-get($config, height);
	    transition: all inf-get($config, transition-duration) ease;
		
		span {
	  		display: block;
	  		position: absolute;
	  		top: (inf-get($config, height) / 2) - (inf-get($config, thickness)) + 1px;
	  		height: inf-get($config, thickness);
	  		min-height: inf-get($config, thickness);
	  		width: 100%;
	  		
	  		@include inf-rounded {
	  			border-radius: inf-get($config, radius);
	  		}

	  		transition: all inf-get($config, transition-duration) ease;

	  		&::before,
	  		&::after {
	  			position: absolute;
			  	display: block;
			  	left: 0;
			  	width: 100%;
			  	height: inf-get($config, thickness);
			  	min-height: inf-get($config, thickness);
			  	content: "";

			  	@include inf-rounded {
			  		border-radius: inf-get($config, radius);
			  	}

			  	transition: all inf-get($config, transition-duration) ease;
	  		}

	  		&::before {
	  			top: -($space);
	  		}

	  		&::after {
	  			bottom: -($space);
	  		}
		}

	    // left direction style
	    &.#{$class}--left {
	    	span {
	    		&:before {
	    			transition: all inf-get($config, transition-duration) ease;
	    			left: auto;
	    			right: 0px;
	    			width: 50%;
	    		}

	    		&:after {
	    			transition: all inf-get($config, transition-duration) ease;
	    			left: auto;
	    			right: 0px;
	    			width: 75%;
	    		}
	    	}

	    	// hover states
	    	&:hover {
	    		span {
		    		&:after, 
		    		&:before {
		    			width: 100%;
		    			transition: all inf-get($config, transition-duration) ease;
		    		}
		    	}
	    	}

	    	// active state
		    &.#{$class}--active {
		    	span {
		    		&:before {
		    			transition: all inf-get($config, transition-duration) ease;
		    			left: 0px;
		    			right: auto;
		    			width: 50%;
		    		}

		    		&:after {
		    			transition: all inf-get($config, transition-duration) ease;
		    			left: 0px;
		    			right: auto;
		    			width: 75%;
		    		}
		    	}
		    }
	    }

	    // right direction style
	    &.#{$class}--right {
	    	span {
		    	&:before {
		    		left: 0px;
		    		right: auto;
		    		width: 50%;
		    		transition: width inf-get($config, transition-duration) ease;
		    	}

		    	&:after {
		    		left: 0px;
		    		right: auto;
		    		width: 75%;
		    		transition: width inf-get($config, transition-duration) ease;
		    	}
		    }

	    	// hover states
	    	&:hover {
	    		span {
		    		&:after, 
		    		&:before {
		    			width: 100%;
		    			transition: width inf-get($config, transition-duration) ease;
		    		}
		    	}
	    	}

	    	// active state
		    &.#{$class}--active {
		    	span {
		    		&:before {
		    			left: auto;
	    				right: 0px;
		    			width: 50%;
		    			transition: width inf-get($config, transition-duration) ease;
		    		}

		    		&:after {
		    			left: auto;
	    				right: 0px;
		    			width: 75%;
		    			transition: width inf-get($config, transition-duration) ease;
		    		}
		    	}
		    }
	    }
	}
}

@mixin inf-burger-icon-skin($class, $config) {
	.#{$class} {
		span {
	 		background: inf-get($config, default);

	  		&::before,
	  		&::after {
			  	background: inf-get($config, default);
	  		}
		}

	    //active & hover state 
	    &:hover {	   
	    	span { 	
		    	background: inf-get($config, hover);

		    	&::before,
		  		&::after {
		  			background: inf-get($config, hover);
		  		}
		  	}
	    }

	    &.#{$class}--active {
	    	span { 	
		    	background: inf-get($config, active);

		    	&::before,
		  		&::after {
		  			background: inf-get($config, active);
		  		}
		  	}
	    }
	}
}