//
// Header Menu
//




// Desktop Mode
@include inf-menu-hor-build-layout(inf-get($inf-header-config, menu, desktop));
@include inf-menu-hor-build-skin(inf-get($inf-header-config, menu, desktop), default);

// Tablet & Mobile Mode
@include inf-menu-ver-build-layout(inf-get($inf-header-config, menu, tablet-and-mobile));
@include inf-menu-ver-build-skin(inf-get($inf-header-config, menu, tablet-and-mobile), default);

// Header Menu Mobile Offcanvas
@include inf-offcanvas-build(inf-header-menu-wrapper, tablet-and-mobile, inf-get($inf-header-config, menu, offcanvas-mobile));

// Desktop mode
@include inf-desktop {
	// Header menu
	.inf-header-menu {
		// Menu nav
		.inf-menu__nav {
			// Menu item
			> .inf-menu__item {
				&:first-child {
					padding-left: 0;
				}

				// Menu link
				> .inf-menu__link {
					padding: 0.7rem 1.1rem;

					@include inf-rounded {
						border-radius: $inf-border-radius;
					}

					.inf-menu__link-text {
						color: #6c7293;
						font-weight: 500;
					}

					.inf-menu__hor-arrow {
						color: #c8cde8;
					}

					.inf-menu__hor-icon {
						color: #c8cde8;
					}					
				}

				// Menu link states
				&:hover,
				&.inf-menu__item--hover,
				&.inf-menu__item--here,
				&.inf-menu__item--active {
					> .inf-menu__link {
						background-color: rgba(inf-brand-color(), 0.05);
								
						.inf-menu__link-text {
							color: inf-brand-color();
						}

						.inf-menu__hor-arrow {
							color: inf-brand-color();
						}

						.inf-menu__hor-icon {
							color: inf-brand-color();
						}
					}	
				}

				// Menu submenu
				> .inf-menu__submenu {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}				
			}
		}
	}
}

// Media range(above 1024px and below 1200px)
@include inf-tablet-and-mobile() {
	.inf-header-menu-wrapper {
		.inf-header-menu {
			.inf-menu__nav {
				> .inf-menu__item {
					padding: 0px 3px;

					> .inf-menu__link {
						.inf-menu__link-text {
							font-size: 1rem;
						}						
					}
				}
			}
		}
	}
}