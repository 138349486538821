//
// Bootstrap Maxlength
//




// Base
@mixin inf-bootstrap-maxlength-base() {
 	.bootstrap-maxlength {
 		z-index: 1040 !important;

 		&.inf-badge {
 			display: inline-flex !important;
 		} 		

 		.modal & {
 			z-index: 1060 !important;
 		}
 	}
}

// Base
@include inf-bootstrap-maxlength-base();