//
// Uppy
//




// Base
.inf-uppy {
	.uppy-Root {
		font-family: inf-get($inf-font-families, regular);

		&.uppy-DragDrop--is-dragdrop-supported {
			border: 2px dashed inf-base-color(grey, 2);
		}
	}

	.uppy-Dashboard-inner {
		background-color: inf-base-color(grey, 1);
		border: 1px solid inf-base-color(grey, 2);

		.uppy-DashboardContent-bar,
		.uppy-Dashboard-AddFilesPanel,
		.uppy-DashboardContent-panel,
		.uppy-DashboardItem-progress,
		.uppy-DashboardItem-action,
		.uppy-DashboardItem-previewLink{
			z-index: 1;
		}

		.uppy-DashboardContent-title {
			color: inf-base-color(label, 2);
			font-size: 1rem;
			font-weight: 500;
		}

		.uppy-DashboardTabs {
			.uppy-Dashboard-dropFilesTitle {
				color: inf-base-color(label, 2);
				font-size: 1.3rem;
				font-weight: 500;
			}

			.uppy-DashboardTab-name{
				color: inf-base-color(label, 2);
			}
		}

		.uppy-DashboardItem.is-complete .progress{
			stroke: inf-state-color(success, base);
			fill: inf-state-color(success, base);
		}

		.uppy-StatusBar-statusSecondary {
			overflow: visible;
		}
	}

	.uppy-DashboardAddFiles-info {
		bottom: 20px !important;
	}

	.uppy-Dashboard-note {
		color: inf-base-color(label, 2);
		font-size: 0.9rem;
		font-weight: 400;
	}

	.uppy-c-btn-primary {
		font-size: 1rem;
		font-weight: 400;
		background: inf-brand-color();
		color: inf-brand-color(inverse);
		outline: none !important;

		&:focus,
		&:hover {
			background-color: darken(inf-brand-color(), 6%);
		}
	}

	.uppy-DashboardContent-back {
		font-size: 1rem;
		font-weight: 500;
		color: inf-brand-color();
		outline: none !important;
		&:focus,
		&:hover {
			color: darken(inf-brand-color(), 6%);
		}
	}

	.uppy-Dashboard-browse {
		color: inf-state-color(brand, base);

		&:focus,
		&:hover {
			color: darken(inf-brand-color(), 6%);
		}
	}

	.uppy-DashboardContent-addMore {
		svg {
			stroke: inf-state-color(brand, base);
			fill: inf-state-color(brand, base);
		}
	}

	.uppy-ProgressBar{
		z-index: 1;
		.uppy-ProgressBar-inner {
			background-color: inf-brand-color();
			box-shadow: none;
		}

		.uppy-ProgressBar-percentage {
			color: inf-base-color(label, 2);
			font-size: 0.9rem;
			font-weight: 400;
		}
	}

	.uppy-DragDrop-label {
		font-size: 1rem;
		font-weight: 500;
		color: inf-base-color(label, 2);

		.uppy-DragDrop-dragText {
			color: inf-state-color(brand, base);
			&:focus,
			&:hover {
				color: darken(inf-brand-color(), 6%);
			}
		}
	}

	.uppy-StatusBar{
		z-index: 1;

		.uppy-StatusBar-status{
			overflow-x: visible;
			display: none;
		}

		.uppy-StatusBar-progress{
			background-color: inf-state-color(brand, base);
		}

		.uppy-StatusBar-content {
			.uppy-StatusBar-statusPrimary {
				font-size: 0.9rem;
				font-weight: 400;
				color: inf-base-color(label, 2);
			}

			.uppy-StatusBar-spinner {
				svg {
					fill: inf-state-color(brand, base);
				}
			}
		}

		.uppy-StatusBar-actions {
			.uppy-StatusBar-actionCircleBtn {
				.UppyIcon {
					circle {
						transition: all 0.3s ease;
						fill: inf-base-color(grey, 2);
					}

					path {
						transition: all 0.3s ease;
						fill: inf-base-color(label, 2);
					}

					&:hover {
						circle {
							transition: all 0.3s ease;
							fill: inf-brand-color();
						}

						path {
							transition: all 0.3s ease;
							fill: inf-brand-color(inverse);
						}
					}
				}
			}
		}

		&.is-complete{
			.uppy-StatusBar-progress{
				background-color: inf-state-color(success, base);
			}
		}

		&.uppy-Root {
			.uppy-StatusBar-content {
				padding-left: 0 !important;
			}

			.uppy-StatusBar-actions {
				right: 0 !important;
			}
		}
	}

	.uppy.uppy-Informer {
		p {
			background-color: rgba(inf-state-color(danger, base), 0.1);
			color: inf-state-color(danger, base);
			border-radius: 0;

			@include inf-rounded {
				border-radius: $inf-border-radius;
			}
		}
	}

	.inf-uppy__progress{
		position: relative;
		display:flex;
		align-items: center;
	}

	.inf-uppy__status{
		@include transition;

		&.inf-uppy__status--ongoing{
			height: 40px;
			opacity: 1;
		}

		&.inf-uppy__status--hidden{
			display: none;
		}
	}

	.inf-uppy__btn{
		margin-top: 10px;
	}

	.inf-uppy__thumbnails{
		display: flex;
		position: relative;
		width: 100%;
		flex-wrap: wrap;
		justify-content: space-between;

		.inf-uppy__thumbnail-container{
			width: calc(50% - 5px);
			margin: 10px 5px 0;
			position: relative;
			display: flex;
			align-items: center;
			height: 50px;
			background-color: inf-base-color(grey, 1);
			border-radius: $inf-border-radius;
			overflow: hidden;

			.inf-uppy__thumbnail{
				width: 60px;
				height: 50px;
				overflow: hidden;
				display: flex;
				justify-content: center;

				img{
					height: 100%;
					width: auto;
				}
			}

			.inf-uppy__thumbnail-label{
				font-weight: 400;
				color: inf-base-color(label, 2);
				margin-left: 10px;
				padding-right: 10px;
			}

			.inf-uppy__remove-thumbnail{
				margin: auto 10px auto auto;
				cursor: pointer;
			}

			&:nth-child(odd){
				margin-left: 0;
			}

			&:nth-child(even){
				margin-right: 0;
			}

			@include inf-minimal-desktop-and-below{
				width: 100%;
				margin: 10px 0 0;
			}
		}
	}

	.inf-uppy__list{
		.inf-uppy__list-item{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.5rem 1rem;
			background-color: inf-base-color(grey, 1);
			margin-top: 0.75rem;
			border-radius: $inf-border-radius;

			&:last-child {
				margin-bottom: 0.75rem;
			}

			.inf-uppy__list-label{
				font-weight: 500;
				color: inf-base-color(label, 2);
			}

			.inf-uppy__list-remove{
				cursor: pointer;
				margin-left: 1rem;

				i {
					transition: color 0.3s ease;
					font-size: 0.7rem;
					color: inf-base-color(label, 1);
				}

				&:hover {
					i {
						transition: color 0.3s ease;
						color: inf-brand-color();
					}
				}
			}
		}
	}

	.inf-uppy__wrapper {
		.uppy-FileInput-container {
			margin-bottom: 0rem;
		}
	}

	.inf-uppy__input-control {
		position: relative;
		z-index: 1;
		height: 0;
		width: 0;
		opacity: 0;
	}

	.inf-uppy__informer--min {
		.uppy-Informer {
			position: static !important;
			top: auto;
			bottom: auto;

			&[aria-hidden="true"] {
				display: none;
			}

			transform: none;
			z-index: 0;

			p {
				display: block;
				max-width: none;
			}
		}
	}
}
