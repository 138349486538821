//
// Navigation
//



// Base
.inf-nav {
	$inf-nav-padding-x: 1.75rem;
	$inf-nav-padding-y: 0.55rem;

	display: block;
	padding: 1rem 0;
	margin: 0;
	list-style: none;
	border: 0; 
	
	// Item
	.inf-nav__item {
		display: block;

		> .inf-nav__link {
			display: flex;
			flex-grow: 1;
			align-items: center;
			padding: $inf-nav-padding-y $inf-nav-padding-x;
			cursor: pointer;
			@include inf-transition(); // menu item link's text part

			.inf-nav__link-text {
				flex-grow: 1;
				font-size: 1rem;
				color: inf-base-color(label, 3);
				font-weight: 400;
			} 
			
			// menu item link's icon
			.inf-nav__link-icon {
				flex: 0 0 30px;
				font-size: 1.25rem;
				line-height: 0;
				color: inf-base-color(label, 1);

				&:before {
					line-height: 0;
				}

				&.inf-nav__link-icon--center {
					text-align: center;
					margin-right: 0.25rem;
				}

				// Svg icon
				@include inf-svg-icon-color(inf-base-color(label, 1));
			} 
			
			// menu item link's bullet
			.inf-nav__link-bullet {
				text-align: center;
				flex: 0 0 10px;
				margin-right: 10px;

				> span {
					vertical-align: middle;
					display: inline-block;
				}

				&.inf-nav__link-bullet--dot {
					> span {
						width: 4px;
						height: 4px;
						border-radius: 100%;
					}
				}

				&.inf-nav__link-bullet--line {
					> span {
						width: 8px;
						height: 2px;
						border-radius: 2px;
					}
				}

				&.inf-nav__link-bullet--line,
				&.inf-nav__link-bullet--dot {
					> span {
						background-color: inf-base-color(label, 1);
					}
				}
			} 
			
			// menu item link's icon part
			.inf-nav__link-badge {
				padding: 0px 0px 0px 5px;
			} 
			
			// menu item link's arrow part
			.inf-nav__link-arrow {
				text-align: right;
				flex: 0 0 20px;
				font-size: 0.8rem;
				color: inf-base-color(label, 1);
				@include inf-la-icon( inf-get($inf-action-icons, down));
				&:before {
					display: inline-block;
					transition: all 0.3s ease;
				}
			}

			&.collapsed {
				.inf-nav__link-arrow {
					@include inf-la-icon( inf-get($inf-action-icons, right));
					&:before {
						transition: all 0.3s ease;
					}
				}
			}
		}

		>.inf-nav__sub {
			padding: 0;
			margin: 0;

			.inf-nav__item {
				>.inf-nav__link {
					padding: 0.5rem 1.5rem 0.5rem 2.5rem;
				}
			}
		}

		&.inf-nav__item--last {
			padding-bottom: 0;
		}
		
		// Hover & active state
		&.inf-nav__item--active,
		&.active,
		&:hover:not(.inf-nav__item--disabled):not(.inf-nav__item--sub) {
			>.inf-nav__link {
				background-color: inf-base-color(grey, 1);

				@include inf-transition();
				
				.inf-nav__link-icon,
				.inf-nav__link-text,
				.inf-nav__link-arrow {
					color: inf-state-color(brand, base);
				}

				.inf-nav__link-icon {
					@include inf-svg-icon-color(inf-state-color(brand, base));
				}
				
				.inf-nav__link-bullet {
					&.inf-nav__link-bullet--line,
					&.inf-nav__link-bullet--dot {
						>span {
							background-color: inf-state-color(brand, base);
						}
					}
				}
			}
		} 
		
		// Hover state
		&:hover:not(.inf-nav__item--disabled).inf-nav__item--sub {
			>.inf-nav__link.collapsed {
				background-color: inf-base-color(grey, 1);

				@include inf-transition();
				
				.inf-nav__link-icon,
				.inf-nav__link-text,
				.inf-nav__link-arrow {
					color: inf-state-color(brand, base);
				}

				
				
				.inf-nav__link-bullet {
					&.inf-nav__link-bullet--line,
					&.inf-nav__link-bullet--dot {
						>span {
							background-color: inf-state-color(brand, base);
						}
					}
				}
			}
		} 
		
		// Disabled state
		&.inf-nav__item--disabled {
			opacity: 0.6;
		}
	} 

	// Custom
	.inf-nav__custom {
		padding:  $inf-nav-padding-y $inf-nav-padding-x;
	} 
	
	// Section
	.inf-nav__section {
		margin-top: 1rem;
		padding: 0 $inf-nav-padding-x;

		.inf-nav__section-icon {
			color: inf-base-color(label, 1);
		}
		
		&.inf-nav__section--first {
			margin-top: 0.5rem;
		}
		
		.inf-nav__section-text {
			color: inf-state-color(brand, base);
			font-weight: 600;
			font-size: 0.9rem;
			text-transform: uppercase;
		}
	} 
	
	// Separator
	.inf-nav__separator {
		margin: ($inf-nav-padding-y + 0.15rem) 0;
		height: 0;
		display: block;
		border-bottom: 1px solid inf-base-color(grey, 2);

		&.inf-nav__separator--dashed {
			border-bottom: 1px dashed inf-base-color(grey, 2);
		}
	}
	
	// Space
	.inf-nav__space {
		height: 0;
		margin: 0.5rem 0;

		&.inf-nav__space--sm {
			margin: 0.25rem 0;
		}

		&.inf-nav__space--lg {
			margin: 0.75rem 0;
		}

		&.inf-nav__space--xl {
			margin: 1rem 0;
		}
	}
	
	// Head & Foot
	.inf-nav__head,
	.inf-nav__foot {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1rem;
		color: inf-base-color(label, 3);
		font-weight: 500;
		padding: $inf-nav-padding-y $inf-nav-padding-x;

		> i {
			font-size: 1.2rem;
			color: inf-base-color(label, 2);
		}
	}

	.inf-nav__head {
		font-size: 1.1rem;
	}
	
	// Fit
	&.inf-nav--fit {
		padding: 0;
		.inf-nav__item {
			>.inf-nav__link {
				padding-left: 0;
				padding-right: 0;
			}
		}
	} 
	
	// Margins sizing
	&.inf-nav--lg-space {
		.inf-nav__item {
			margin: 0.35rem 0;

			.inf-nav__link {
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;
			}
			
			&:first-child {
				margin-top: 0;
			}
			
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.inf-nav--md-space {
		.inf-nav__item {
			margin: 0.25rem 0;
			
			.inf-nav__link {
				padding-top: 0.75rem;
				padding-bottom: 0.75rem;
			}
			
			&:first-child {
				margin-top: 0;
			}
			
			&:last-child {
				margin-bottom: 0;
			}
		}
	} 
	
	// font weight
	&.inf-nav--bold {
		.inf-nav__item {
			.inf-nav__link {
				.inf-nav__link-text {
					font-weight: 500;
				}
			}
		}
	}

	&.inf-nav--thin {
		.inf-nav__item {
			.inf-nav__link {
				.inf-nav__link-text {
					font-weight: 300;
				}
			}
		}
	} 
	
	// font sizing
	&.inf-nav--lg-font {
		.inf-nav__item {
			.inf-nav__link {
				.inf-nav__link-text {
					font-size: 1.15rem;
				}
			}
		}
	}

	&.inf-nav--xl-font {
		.inf-nav__item {
			.inf-nav__link {
				.inf-nav__link-text {
					font-size: 1.25rem;
				}
			}
		}
	}
} 

// Version 2
.inf-nav {
	&.inf-nav--v2 {
		.inf-nav__item {
			border-radius: 2rem;
			overflow: hidden;

			>.inf-nav__link {
				transition: none;
			} 
			
			// Hover state
			&:hover:not(.inf-nav__item--disabled):not(.inf-nav__item--sub):not(.inf-nav__item--active) {
				>.inf-nav__link {
					background-color: #f4f4f9;
					transition: none;

					.inf-nav__link-icon,
					.inf-nav__link-text,
					.inf-nav__link-arrow {
						color: inf-state-color(brand, base);
					}
					
					.inf-nav__link-bullet {
						&.inf-nav__link-bullet--line,
						&.inf-nav__link-bullet--dot {
							>span {
								background-color: #f4f4f9;
							}
						}
					}
				}
			} 
			
			// Active state
			&.inf-nav__item--active {
				>.inf-nav__link {
					background-color: inf-state-color(brand, base);
					transition: none;

					.inf-nav__link-icon,
					.inf-nav__link-text,
					.inf-nav__link-arrow {
						color: inf-state-color(brand, inverse);
					}
					
					.inf-nav__link-bullet {
						&.inf-nav__link-bullet--line,
						&.inf-nav__link-bullet--dot {
							>span {
								background-color: inf-state-color(brand, inverse);
							}
						}
					}
				}
				&:hover {
					>.inf-nav__link {
						background-color: inf-state-color(brand, base);

						.inf-nav__link-icon,
						.inf-nav__link-text,
						.inf-nav__link-arrow {
							color: inf-state-color(brand, inverse);
						}
						
						.inf-nav__link-bullet {
							&.inf-nav__link-bullet--line,
							&.inf-nav__link-bullet--dot {
								>span {
									background-color: inf-state-color(brand, inverse);
								}
							}
						}
					}
				}
			}
		}
	}
} 

// Version 3
.inf-nav {
	&.inf-nav--v3 {
		.inf-nav__item {
			margin: 0;

			.inf-nav__link {
				@include inf-transition();
				padding-top: 1rem;
				padding-bottom: 1rem;
				border-left: 2px solid transparent;
			}

			&.active,
			&.inf-nav__link--active {
				.inf-nav__link {
					@include inf-transition();
					background-color: inf-base-color(grey, 1);
					border-left: 2px solid inf-state-color(brand);
					
					.inf-nav__link-text {
						color: inf-state-color(brand);
					}
				}
			}
				
			&:hover {
				@include inf-transition();
				background-color: inf-base-color(grey, 1);

				.inf-nav__link {
				   .inf-nav__link-text {
						color: inf-state-color(brand);
					}
				}
			}
		}
	}
}

// Version 4
.inf-nav {
	&.inf-nav--v4 {
		
		.inf-nav__item{
			padding: 0.65rem 0;

			.inf-nav__link{
				background-color: transparent;
				margin-left:3px;
				color: #9492a1;
				font-size: 1.2rem;
				padding: 0 1.1rem;

				.inf-nav__link-text{
					color: #9492a1;
					font-size: 1.2rem;
				}
			}

			&.active{

				.inf-nav__link{
					border-left: 3px solid inf-state-color(brand);  
					color: inf-base-color(label, 4); 
					margin-left:0; 	

					.inf-nav__link-text{
						color: inf-base-color(label, 4);						  
					}
				}				
			}

			&:first-child{
				margin-top: -0.65rem;
			}

			&:hover {
				@include inf-transition();
				background-color: inf-base-color(grey, 1);

				.inf-nav__link {
				   .inf-nav__link-text {
						color: inf-state-color(brand);
					}
				}
			}
		}

		@each $name, $color in $inf-state-colors{

			&--#{$name}{

				.inf-nav__item{
					&.active{
						.inf-nav__link{
							border-left: 3px solid inf-state-color(#{$name}, base);  
						}
					}

					&:hover:not(.inf-nav__item--disabled):not(.inf-nav__item--sub):not(.inf-nav__item--active) {
						>.inf-nav__link{
							.inf-nav__link-text {
								color: inf-state-color(#{$name}, base);
							}
						}
					}
				}
			}
		}
	}
}