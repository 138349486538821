//
// CKEditor
//




// Base
.ck-target{
    display: none;
}

.ck-toolbar{
    border-radius: $inf-border-radius !important;
}

.ck-content{
    min-height: 200px;
    border-radius: $inf-border-radius !important;

    &.ck-focused{
        border-color:  inf-state-color(brand, base) !important;
        box-shadow: none !important;
    }
}  

// CKEditor Classic & Document
.ck-editor{
    .ck-toolbar{
        border-top-left-radius: $inf-border-radius !important;
        border-top-right-radius: $inf-border-radius !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }   
    .ck-content{
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: $inf-border-radius !important;
        border-bottom-right-radius: $inf-border-radius !important;
    }
}

// CKEditor Inline / Balloon / Balloon Block
.ck-body .ck-balloon-panel{

    .ck-content{
        min-height: 200px;
        border-color: transparent !important;
        
        &.ck-focused{
            border-color:  inf-state-color(brand, base) !important;
        }
    }

    &.ck-toolbar-container, .ck-toolbar{
        border-radius: $inf-border-radius !important;
    }
}



