//
// Widget15
//




.inf-widget15 {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    // Items
    .inf-widget15__items {
        flex-grow: 1;   

        &.inf-widget15__items--bottom {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        // Item
        .inf-widget15__item {
            // Stats
            .inf-widget15__stats {
                font-size: 1.1rem;
                font-weight: 500; 
                color: inf-base-color(label, 2);
            }
                
            // Text
            .inf-widget15__text {
                font-size: 1rem;
                float: right;
                margin-top: 0.3rem;
                font-weight: 400;
                color: inf-base-color(label, 2);
            }

            // Progress
            .progress {
                margin-bottom: 1.6rem;
                height: 0.45rem
            }    
        }          
        
        // Description
        .inf-widget15__desc {
            margin-top: 0.5rem;
            font-size: 0.9rem;
            font-weight: 400; 
            color: inf-base-color(label, 2);
        } 
    }          

    .inf-widget4__chart {
    }

    .inf-widget15__map {

    }
}     	