//
// Widget 26
//



 
.inf-widget26 {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .inf-widget26__content {
        display: flex;           
        padding-bottom: 1rem;      
        flex-grow: 1;     
        display: flex;
        flex-direction: column;

        .inf-widget26__number {
            font-weight: 600;
            font-size: 1.8rem;
        } 

        .inf-widget26__desc {
            color: inf-base-color(label, 2);
            font-size: 1.1rem;
            font-weight: 400;
            margin-top: 0.55rem;
        }                           
    }   

    .inf-widget26__chart {
        position: relative;

        canvas {
            border-bottom-left-radius: $inf-border-radius;
            border-bottom-right-radius: $inf-border-radius;
        }
    }
}  