//
// Popover
//



// Base
.popover {
    box-shadow: $popover-box-shadow;

    .popover-header {
        font-size: 1rem;
        font-weight: 500;
        border-bottom: 1px solid inf-base-color(grey, 2);
    }
}