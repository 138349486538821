//
// Helpers
//




// Margin & Padding Helpers
@mixin inf-margin-and-padding($mode) {
	@if $mode == general {
		$mode: '';
	} @else {
		$mode: "-" + $mode;
	}

	@for $i from 0 through 20 {
		$num: $i * 5;

		.inf-margin-#{$num}#{$mode} {
			margin: #{$num}px !important;
		}

		.inf-margin-t-#{$num}#{$mode} {
			margin-top: #{$num}px !important;
		}

		.inf-margin-b-#{$num}#{$mode} {
			margin-bottom: #{$num}px !important;
		}

		.inf-margin-l-#{$num}#{$mode} {
			margin-left: #{$num}px !important;
		}

		.inf-margin-r-#{$num}#{$mode} {
			margin-right: #{$num}px !important;
		}

		.inf-padding-#{$num}#{$mode} {
			padding: #{$num}px !important;
		}

		.inf-padding-t-#{$num}#{$mode} {
			padding-top: #{$num}px !important;
		}

		.inf-padding-b-#{$num}#{$mode} {
			padding-bottom: #{$num}px !important;
		}

		.inf-padding-l-#{$num}#{$mode} {
			padding-left: #{$num}px !important;
		}

		.inf-padding-r-#{$num}#{$mode} {
			padding-right: #{$num}px !important;
		}

		// short format
		.inf-m#{$num}#{$mode} {
			margin: #{$num}px !important;
		}

		.inf-mt-#{$num}#{$mode} {
			margin-top: #{$num}px !important;
		}

		.inf-mb-#{$num}#{$mode} {
			margin-bottom: #{$num}px !important;
		}

		.inf-ml-#{$num}#{$mode} {
			margin-left: #{$num}px !important;
		}

		.inf-mr-#{$num}#{$mode} {
			margin-right: #{$num}px !important;
		}

		.inf-p#{$num}#{$mode} {
			padding: #{$num}px !important;
		}

		.inf-pt#{$num}#{$mode} {
			padding-top: #{$num}px !important;
		}

		.inf-pb-#{$num}#{$mode} {
			padding-bottom: #{$num}px !important;
		}

		.inf-pl#{$num}#{$mode} {
			padding-left: #{$num}px !important;
		}

		.inf-pr#{$num}#{$mode} {
			padding-right: #{$num}px !important;
		}
	}
}

@include inf-margin-and-padding(general);

@include inf-desktop {
	@include inf-margin-and-padding(desktop);
}

@include inf-tablet {
	@include inf-margin-and-padding(tablet);
}

@include inf-tablet-and-mobile {
	@include inf-margin-and-padding(tablet-and-mobile);
}

@include inf-mobile {
	@include inf-margin-and-padding(mobile);
}

// Opacity
@mixin inf-opacity {
	@for $i from 1 through 9 {
		.inf-opacity-#{$i} {
			opacity: #{$i * 0.1};
		} 
	}
}

@include inf-opacity();

// Border radius
.inf-radius-100 {
	border-radius: 100%;
} 

// General helpers
.inf-valign-top {
	vertical-align: top;
} 

.inf-space-5 {
	display: block;
	height: 0;
	margin-bottom: 5px;
}

.inf-space-10 {
	display: block;
	height: 0;
	margin-bottom: 10px;
}

.inf-space-15 {
	display: block;
	height: 0;
	margin-bottom: 15px;
}

.inf-space-20 {
	display: block;
	height: 0;
	margin-bottom: 20px;
}

.inf-space-30 {
	display: block;
	height: 0;
	margin-bottom: 30px;
}

.inf-space-40 {
	display: block;
	height: 0;
	margin-bottom: 40px;
}

.inf-valign-middle {
	vertical-align: middle;
}

.inf-valign-bottom {
	vertical-align: bottom;
}

.inf-block-center {
	margin-left: auto;
	margin-right: auto;
}

.inf-align-right {
	text-align: right;
}

.inf-align-left {
	text-align: left;
}

.inf-align-center {
	text-align: center;
}

.inf-pull-right {
	float: right;
}

.inf-pull-left {
	float: left;
}

.inf-img-rounded {
	border-radius: 50% !important;
}

.inf-block-inline {
	display: inline-block;
}

.inf-icon-middle {
	&:before {
		vertical-align: middle;
	}
}

// Element visibility
.inf-hidden,
.inf-hide {
	display: none !important;
}

// Font sizing
.inf-font-sm {
	font-size: 0.8rem !important;
}

.inf-font-md {
	font-size: 0.9rem !important;
}

.inf-font-lg {
	font-size: 1.2rem !important;
}

.inf-font-xl {
	font-size: 1.5rem !important;
}

.inf-clearfix {
	@include inf-clearfix();
}

// Icon font sizing
.inf-icon-sm {
	font-size: 0.75rem !important;
}

.inf-icon-md {
	font-size: 0.9rem !important;
}

.inf-icon-lg {
	font-size: 1.2rem !important;
}

.inf-icon-xl {
	font-size: 1.4rem !important;
}


.inf-marginless {
	margin: 0 !important;
}

.inf-img-centered {
	text-align: center;
}

// Text Transform
.inf-font-transform-u {
	text-transform: uppercase;
}

.inf-font-transform-l {
	text-transform: lowercase;
}

.inf-font-transform-c {
	text-transform: capitalize;
}

.inf-font-transform-i {
	text-transform: initial;
}

// Font Weights
.inf-font-regular {
	font-weight: 400 !important;
}
.inf-font-bold {
	font-weight: 500 !important;
}

.inf-font-bolder {
	font-weight: 600 !important;
}

.inf-font-boldest {
	font-weight: 700 !important;
}

b, strong {
    font-weight: bold;
}

// State colors
@each $name, $color in $inf-state-colors {
	.inf-font-#{$name} {
		color: inf-get($color, base) !important;
	}

	.inf-font-hover-#{$name}{
		&:hover{
			color: darken(inf-get($color, base),20%);
		}
	}

	.inf-font-inverse-#{$name} {
		color: inf-get($color, inverse) !important;
	}

	.inf-bg-#{$name} {
		background-color: inf-get($color, base) !important;
	}

	.inf-bg-fill-#{$name} {
		background-color: inf-get($color, base) !important;
		color: inf-get($color, inverse) !important;

		> i {
			color: inf-get($color, inverse) !important;
		}
	}

	.inf-bg-light-#{$name} {
		background-color: rgba(inf-get($color, base), 0.1) !important;
	}
}

// Social colors
@each $name, $color in $inf-social-colors {
	.inf-font-#{$name} {
		color: inf-get($color, base) !important;
	}
}

// Base colors
@each $name, $color in inf-get($inf-base-colors, label) {
	.inf-label-font-color-#{$name} {
		color: $color !important; 
	}

	.inf-label-bg-color-#{$name} {
		background-color: $color !important; 
	}
}

@each $name, $color in inf-get($inf-base-colors, shape) {
	.inf-shape-font-color-#{$name} {
		color: $color !important; 
	}

	.inf-shape-bg-color-#{$name} {
		background-color: $color !important; 
	}
}

// Responsive Helpers
.inf-visible-desktop,
.inf-visible-desktop-inline-block,
.inf-visible-desktop-inline,
.inf-visible-desktop-table,
.inf-visible-desktop-table-cell,

.inf-visible-tablet,
.inf-visible-tablet-inline-block,
.inf-visible-tablet-inline,
.inf-visible-tablet-table,
.inf-visible-tablet-table-cell,

.inf-visible-tablet-and-mobile,
.inf-visible-tablet-and-mobile-inline-block,
.inf-visible-tablet-and-mobile-inline,
.inf-visible-tablet-and-mobile-table,
.inf-visible-tablet-and-mobile-table-cell,

.inf-visible-mobile,
.inf-visible-mobile-inline-block,
.inf-visible-mobile-inline,
.inf-visible-mobile-table,
.inf-visible-mobile-table-cell {
	display: none !important;
}

@include inf-desktop {
	.inf-visible-desktop {
		display: block !important;
	}

	.inf-visible-desktop-inline-block {
		display: inline-block !important;
	}

	.inf-visible-desktop-inline {
		display: inline !important;
	}

	.inf-visible-desktop-table {
		display: table !important;
	}	

	.inf-visible-desktop-table-cell {
		display: table-cell !important;
	}	

	.inf-hidden-desktop {
		display: none !important;
	}
}

@include inf-tablet {
	.inf-visible-tablet {
		display: block !important;
	}

	.inf-visible-tablet-inline-block {
		display: inline-block !important;
	}

	.inf-visible-tablet-inline {
		display: inline !important;
	}

	.inf-visible-tablet-table {
		display: table !important;
	}	

	.inf-visible-tablet-table-cell {
		display: table-cell !important;
	}	

	.inf-hidden-tablet {
		display: none !important;
	}
}

@include inf-tablet-and-mobile {
	.inf-visible-tablet-and-mobile {
		display: block !important;
	}

	.inf-visible-tablet-and-mobile-inline-block {
		display: inline-block !important;
	}

	.inf-visible-tablet-and-mobile-inline {
		display: inline !important;
	}

	.inf-visible-tablet-and-mobile-table {
		display: table !important;
	}	

	.inf-visible-tablet-and-mobile-table-cell {
		display: table-cell !important;
	}	

	.inf-hidden-tablet-and-mobile {
		display: none !important;
	}
}

@include inf-mobile {
	.inf-visible-mobile {
		display: block !important;
	}

	.inf-visible-mobile-inline-block {
		display: inline-block !important;
	}

	.inf-visible-mobile-inline {
		display: inline !important;
	}

	.inf-visible-mobile-table {
		display: table !important;
	}	

	.inf-visible-mobile-table-cell {
		display: table-cell !important;
	}	

	.inf-hidden-mobile {
		display: none !important;
	}
}

// Flexbox helpers

.inf-space-between {
	display: flex;
	justify-content: space-between;
}

// Rounded
.inf-rounded {
	border-radius: $inf-border-radius !important;
}

// Negative Spacing
.inf-negative-spacing {
	@for $i from 1 through 10 {		
		&--#{$i}{
			margin-top: -#{$i}0px;
		}
	} 
}