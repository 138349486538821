//
// Widget24
//




.inf-widget24 {
    padding: inf-get($inf-portlet, space, desktop);
    flex: 1;

    .inf-widget24__details {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .inf-widget24__info {
            .inf-widget24__title {
                display: flex;
                font-size: 1.1rem;
                font-weight: 500;
                color: inf-base-color(label, 3);
                transition: color 0.3s ease;

                &:hover {
                    transition: color 0.3s ease;
                    color: inf-brand-color();
                }
            }

            .inf-widget24__desc {
                color: inf-base-color(label, 2);
                font-weight: 400;
            }
        }

        .inf-widget24__stats {
            font-size: 1.75rem;
            font-weight: 500;
            padding-left: 0.5rem;
        }
    }

    .progress {
        height: 0.5rem;
        margin: 2rem 0 0.5rem 0;
    }

    .inf-widget24__action {
        display: flex;
        justify-content: space-between;
        font-weight: 400;

        .inf-widget24__change {
            color: inf-base-color(label, 2);
        }

        .inf-widget24__number {
            color: inf-base-color(label, 2);
        }
    }

    // solid bg 
    &.inf-widget24--solid {
        border: 1px solid inf-base-color(grey, 2);
        padding: 1rem;

        @include inf-rounded {
            border-radius: $inf-border-radius;
        }
    }
}


@include inf-tablet-and-mobile {
    .inf-widget24 {
        padding: inf-get($inf-portlet, space, mobile);
    }
}
