//
// Markdown
//




// Base
@mixin inf-bootstrap-markdown-base() {
    .md-editor {
        @include inf-rounded {
            border-radius: $inf-border-radius;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        @include inf-not-rounded {
            border-radius: 0 !important;
        }

        outline: none !important;

        .md-footer, 
        .md-header {
            padding: 10px 10px;
        }

        .md-header {
            @include inf-rounded {
                border-top-left-radius: $inf-border-radius;
                border-top-right-radius: $inf-border-radius;
            }

            @include inf-not-rounded {
                border-radius: 0 !important;
            }

            .btn-group {
                margin-right: 10px;

                .btn {
                    background: white;
                    border: 1px solid inf-base-color(grey, 2);
                    color: inf-base-color(label, 2);
                    @include inf-transition();

                    &:hover,
                    &:focus,
                    &:active,
                    &.active {
                        @include inf-transition();
                        color: inf-base-color(label, 3);
                        background: transparent;
                    }
                }
            }
        }

        .md-footer {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            @include inf-not-rounded {
                border-radius: 0 !important;
            }
        }

        > textarea {
            padding: 10px 10px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            @include inf-not-rounded {
                border-radius: 0 !important;
            }
        }
    }
}

// Skin
@mixin inf-bootstrap-markdown-skin() {
    .md-editor {
        border: 1px solid $input-border-color; 

        &.active {
            border: 1px solid  $input-border-color; 
            box-shadow: none;
        }

        .md-footer, 
        .md-header {
            background: inf-base-color(grey, 1);
        }

        > textarea {
            background: inf-base-color(grey, 1);
        }

        &.active {
            > textarea {
                background: inf-base-color(grey, 1);
            }
        }
    }

    // Validation
    .is-valid {
        .md-editor {
            border-color: inf-state-color(success, base);
        }
    }

    .is-invalid {
        .md-editor {
            border-color: inf-state-color(danger, base);
        }
    }
}

@mixin inf-bootstrap-markdown-validation-state($state, $color) {
    .inf-form.inf-form--state .has-#{$state} {
        .md-editor {
            border: 1px solid $color; 
        }
    }
}


// Base
@include inf-bootstrap-markdown-base();

// Skin
@include inf-bootstrap-markdown-skin();