//
// Heading
//



.inf-heading {
	font-size: 1.3rem;
	font-weight: 500;
	padding: 0;
	margin: 1.75rem 0;
	color: inf-base-color(label, 4);

	&.inf-heading--center {
		text-align: center;
	}

	&.inf-heading--right {
		text-align: right;
	}

	&.inf-heading--space-sm {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	&.inf-heading--space-md {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	&.inf-heading--space-lg {
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
	}

	&.inf-heading--space-xl {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}

	&.inf-heading--sm {
		font-size: 1.2rem;
	}

	&.inf-heading--md {
		font-size: 1.4rem;
	}

	&.inf-heading--lg {
		font-size: 1.6rem;
	}

	&.inf-heading--xl {
		font-size: 2rem;
	}

	&.inf-heading--xxl {
		font-size: 3rem;
	}

	&.inf-heading--medium{
		font-weight: 600;
	}
	&.inf-heading--bold{
		font-weight: 700;
	}
	&.inf-heading--bolder{
		font-weight: 900;
	}
	&.inf-heading--thin{
		font-weight: 300;
	}

	&.inf-heading--no-top-margin{
		margin-top: 0;
	}
	&.inf-heading--no-bottok-margin{
		margin-bottom: 0;
	}
}