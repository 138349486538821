//
// Code Customization
// 



code {
    background-color: inf-base-color(grey, 1);
    padding: 0.15rem 0.25rem;

    @include inf-rounded {
        border-radius: $inf-border-radius;
    }
}