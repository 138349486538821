//
// Widget32
//




.inf-widget__files {  
  .inf-widget__media {
    text-align: center;

    img {
      width: 5rem;
    }

    g [fill] {
        fill: rgba(40, 42, 60, 0.2);
    }

    .inf-widget__icon {
      height: 5rem;
      display: block;
      text-align: center;

      svg {
          width: 4rem;
          height: 4rem;
      }
    }
  }

  .inf-widget__desc {
    text-align: center;
    display: block;
    font-weight: 500;
    color: inf-base-color(label, 3);
    font-size: 1.2rem;
    padding-top: 5rem;

    &:hover {
      color: inf-brand-color();
      transition: all 0.3s ease;
    }

    &.inf-widget__desc--m {
      padding-top: 1rem;
      margin-bottom: 4rem;
    }
  }
}
