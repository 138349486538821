//
// Divider
//

// Base
@mixin inf-divider-base() {
    .inf-divider {
    	display: flex;
        justify-content: center;
        align-items: center;      

        > span {             
            &:first-child {
                width: 100%;
                height: 1px;                 
                flex: 1;
                background: #EBECF1;
                display: inline-block;
            }

            &:last-child {
                width: 100%;
                height: 1px;               
                flex: 1;
                background: #EBECF1;
                display: inline-block;
            }

            &:not(:first-child):not(:last-child) {
                padding: 0 2rem;                           
            }
        }
    }
}

// Build
@include inf-divider-base();