//
// Table
//



.table {

	th, td{
		&.table-center{
			text-align: center;
		}
	}

	thead {
		th, td {
			font-weight: 500;
			border-bottom-width: 1px;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	tr.table-active {
		td, th {
			color: inf-state-color(primary, inverse);
		}
	}

	&.table-head-noborder {
		thead {
			th, td {
				border-top: 0;
			}
		}
	}

	&.table-head-solid {
		thead {
			th, td {
				background-color: inf-base-color(grey, 1);
			}
		}
	}

	&.table-light{
		tr{
			border-radius: 5px;
			color: #9392a0;

			&:nth-child(even){
				background-color: inf-base-color(grey,1);	
			}	

			td.table-row-title{
				color: inf-base-color(label, 4);
			}
		}

		thead{
			tr{
				background-color: rgba(inf-state-color(brand, base), 0.15);
				color: inf-state-color(brand, base);
			}
		}

		@each $name, $color in $inf-state-colors{

			&--#{$name}{
				thead{
					tr{
						color: inf-state-color(#{$name}, base);
						background-color: rgba(inf-state-color(#{$name}, base), 0.15);
					}
				}
			}
		}
	}
}

.table:not(.table-bordered) {
	thead {
		th, td {
			border-top: 0;
		}
	}
}